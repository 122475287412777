<template>
  <b-modal id="ads-modal" title="Add Display Ad" hide-footer>
    <FormulateForm class="login-form" v-model="formValues" @submit="submitForm">
      <FormulateInput
        name="name"
        type="text"
        label="Ad name"
        placeholder="Ad name"
        validation="required"
      />

      <FormulateInput
        type="date"
        name="start_date"
        label="Start date"
        validation="optional"
      />

      <FormulateInput
        type="date"
        name="finish_date"
        label="Finish date"
        validation="optional"
      />
      <div class="float-right">
        <FormulateInput
          type="submit"
          :label="creating ? 'Creating...' : 'Add Ads'"
        />
      </div>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { display_ads } from "@/config/api/display_ads";
export default {
  name: "BannerModal",
  methods: {
    submitForm(value) {
      const data = { ...value };

      data.start_date = this.moment(data.start_date).format("MM-DD-YYYY");
      data.finish_date = this.moment(data.finish_date).format("MM-DD-YYYY");
      const api = display_ads.createDisplayAd;
      api.data = data;
      this.creating = true;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("New Ad created!", {
            title: "Ads",
            variant: "success",
            solid: true,
          });
          this.$emit("added");
          this.$bvModal.hide("ads-modal");
        })
        .catch((err) => {
          this.$bvToast.toast(`Ad is not created!,${err.response.data.error}`, {
            title: "Ads",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.creating = false;
        });
    },
  },
};
</script>

<style scoped>
/* Your styles here */
</style>
