<template>
  <Layout>
    <b-button style="margin-bottom: 10px" @click="openModal">
      Add Display Ad
    </b-button>
    <div class="clearfix"></div>
    <div class="card campign">
      <div class="card-body rounded-lg">
        <!-- Removed b-tabs and b-tab components -->
        <Table
          :status="0"
          :reload="reloadData"
          @reloaded="reloadData = false"
        />
      </div>
    </div>
    <BannerModal @added="handleAddedEvent" />
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import { campaignData } from "@/data/campaign";
import Table from "@/components/displayads/displayads-table";
import BannerModal from "@/components/displayads/action-modal";

export default {
  data() {
    return {
      campaignData: campaignData,
      reloadData: false,
      selectedBanner: null,
    };
  },
  components: { Layout, Table, BannerModal },
  methods: {
    openModal() {
      this.$bvModal.show("ads-modal");
    },
    hideActionModal() {
      this.$bvModal.hide("ads-modal");
    },
    handleAddedEvent() {
      this.reloadData = true;
    },
  },
};
</script>

<style>
.campign .nav-tabs > li > a,
.nav-pills > li > a {
  color: rgb(85, 83, 83);
}
</style>
