<template>
  <div>
    <div
      class="row"
      style="
        border: 1px solid #ced4d9;
        margin: 0;
        border-top: none;
        padding-bottom: 18px;
      "
    >
      <div class="col-12">
        <div class="table-responsive mb-0">
          <b-table
            :items="displayAds"
            :fields="fields"
            responsive="sm"
            :busy="isBusy"
            :per-page="perPage"
            tbody-tr-class="rowClass"
            @row-clicked="displayAdSelected"
            show-empty
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #empty>
              <p style="text-align: center; margin-top: 70px">No display ads</p>
            </template>

            <template #cell(name)="row">
              {{ row.item.name || "-" }} <br />
            </template>
            <template #cell(status)="row">
              <b-badge v-if="row.item.status === 1">Active</b-badge>
              <b-badge v-else>InActive</b-badge>
            </template>
            <template #cell(createdAt)="row">
              {{
                new Date(row.item.createdAt).toLocaleString("en-US", {
                  dateStyle: "short",
                  timeStyle: "short",
                })
              }}
              <br />
            </template>
            <template #cell(updatedAt)="row">
              {{
                new Date(row.item.updatedAt).toLocaleString("en-US", {
                  dateStyle: "short",
                  timeStyle: "short",
                })
              }}
              <br />
            </template>
            <template #cell(start_date)="row">
              {{
                row.item.start_date
                  ? new Date(row.item.start_date).toLocaleString("en-US", {
                      dateStyle: "short",
                      timeStyle: "short",
                    })
                  : "-"
              }}
              <br />
            </template>
            <template #cell(finish_date)="row">
              {{
                row.item.start_date
                  ? new Date(row.item.finish_date).toLocaleString("en-US", {
                      dateStyle: "short",
                      timeStyle: "short",
                    })
                  : "-"
              }}
              <br />
            </template>
            <template #cell(assigned_group)="row">
              <div
                v-if="row.item.assigned_group && row.item.assigned_group.length"
              >
                <div
                  v-for="(item, index) in row.item.assigned_group"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
              <div v-else>No assigned group</div>
            </template>
            <template #cell(assigned_devices)="row">
              <div
                v-if="
                  row.item.assigned_devices && row.item.assigned_devices.length
                "
              >
                <div
                  v-for="(item, index) in row.item.assigned_devices"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
              <div v-else>No assigned devices</div>
            </template>
            <template #cell(campaign_files)="row">
              <div
                v-if="row.item.campaign_files && row.item.campaign_files.length"
              >
                {{ row.item.campaign_files.length }} files
              </div>
              <div v-else>-</div>
            </template>

            <template #cell(action)="row">
              <b-button
                size="sm"
                variant="secondary"
                class="ml-1"
                @click="openModalDevice(row.item._id)"
              >
                Assign device
              </b-button>
              <b-button
                size="sm"
                variant="primary"
                class="ml-1"
                @click="openModal(row.item._id)"
              >
                Add file
              </b-button>
            </template>
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  @change="updatePage"
                >
                </b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddFileModal
      :showModal="showModal"
      :displayadId="displayadId"
      @close="showModal = false"
      @refreshAds="getAllAds"
    />
    <AddDeviceModal
      :showModalDevice="showModalDevice"
      :displayadDeviceId="displayadDeviceId"
      @close="showModalDevice = false"
      @refreshAds="getAllAds"
    />
  </div>
</template>

<script>
import { display_ads } from "@/config/api/display_ads";
// import Swal from "sweetalert2";
import AddFileModal from "./add-file-modal.vue";
import AddDeviceModal from "./add-device-modal.vue";

export default {
  props: ["status", "reload"],
  data() {
    return {
      displayAds: [],
      displayadId: null,
      displayadDeviceId: null,
      showModal: false,
      showModalDevice: false,
      isBusy: false,
      currentPage: this.$store.getters["displayad/currentPage"],
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      fields: [
        { key: "name", sortable: false },
        { key: "status", sortable: false },
        { key: "createdAt", sortable: false },
        { key: "updatedAt", sortable: false },
        { key: "start_date", sortable: false },
        { key: "finish_date", sortable: false },
        { key: "assigned_group", sortable: false },
        { key: "assigned_devices", sortable: false },
        { key: "campaign_files", sortable: false },
        { key: "action", sortable: false },
      ],
    };
  },
  components: { AddFileModal, AddDeviceModal },
  computed: {
    rows() {
      return this.$store.getters["displayad/rows"];
    },
  },
  created() {
    this.getAllAds();
  },
  methods: {
    getAllAds() {
      const api = display_ads.list;
      this.isBusy = true;
      this.generateAPI(api)
        .then((res) => {
          this.displayAds = res.data.ads;
          this.isBusy = false;
        })
        .catch((error) => {
          console.error("API call error: ", error);
          this.isBusy = false;
        });
    },
    displayAdSelected(row) {
      // this.$store.dispatch("campaign/selectCampaign", row);
      this.$router.push({
        name: "displayAdsDetails",
        params: { id: row._id },
      });
    },
    openModal(id) {
      this.displayadId = id;
      this.showModal = true;
    },
    openModalDevice(id) {
      this.displayadDeviceId = id;
      this.showModalDevice = true;
    },
    reloadData() {
      this.getAllAds();
      this.$emit("reloaded");
    },
    updatePage(page) {
      this.$store.dispatch("displayad/changePage", page);
      this.getAllCampigns(page);
    },
  },
  watch: {
    reload(val) {
      if (val) {
        this.reloadData();
      }
    },
  },
};
</script>

<style>
.rowClass {
  cursor: pointer;
}
.custom-modal-backdrop .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.rowClass:hover {
  background: #f2f2f2;
}
</style>
