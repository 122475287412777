<template>
  <b-modal v-model="showModal" @hide="handleHide" title="Add File" hide-footer>
    <b-form @submit.prevent="saveFile">
      <b-form-group label="File">
        <b-form-file
          v-model="file"
          required
          accept=".jpeg,.jpg,.png,.mp4"
        ></b-form-file>
      </b-form-group>
      <b-form-group label="Duration">
        <b-form-input v-model="duration" type="number" required></b-form-input>
      </b-form-group>
      <b-button type="submit" variant="primary" :disabled="isSaving">
        <b-spinner v-if="isSaving" small></b-spinner>
        <span v-if="!isSaving">Save</span>
        <span v-if="isSaving">Saving...</span>
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
import { display_ads } from "@/config/api/display_ads";

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    displayadId: {
      type: String,
    },
  },
  data() {
    return {
      file: null,
      duration: null,
      isSaving: false,
    };
  },
  methods: {
    handleHide() {
      this.$emit("close");
    },
    async saveFile() {
      if (!this.isValidFile(this.file)) {
        this.$bvToast.toast(
          "Invalid file format! Only JPEG, JPG, PNG, and MP4 files are allowed.",
          {
            title: "Ads",
            variant: "danger",
            solid: true,
          }
        );
        return;
      }

      this.isSaving = true;
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("duration", this.duration);
      formData.append("displayAdId", this.displayadId);

      const api = display_ads.uploadFile;
      api.data = formData;
      this.generateAPI(api)
        .then((res) => {
          this.displayAds = res.data;
          this.isSaving = false;
          this.$emit("refreshAds");
          this.$emit("close");
          this.$bvToast.toast("New Campaign File created!", {
            title: "Ads",
            variant: "success",
            solid: true,
          });
        })
        .catch((error) => {
          this.isSaving = false;
          this.$bvToast.toast(
            `File is not uploaded! ${error.response.data.error}`,
            {
              title: "Ads",
              variant: "danger",
              solid: true,
            }
          );
        });
    },
    isValidFile(file) {
      const allowedExtensions = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "video/mp4",
      ];
      return file && allowedExtensions.includes(file.type);
    },
  },
};
</script>

<style scoped>
.custom-modal-backdrop .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
</style>
