export const display_ads = {
  list: {
    url: "/display_ad/admin/get_all",
    method: "GET",
    params: null,
  },
  getById:{
    url: `/display_ad/admin/get/`,
    method: "GET",
    params: null,
    id: null,
  },
  createDisplayAd: {
    url: "/display_ad/admin/create",
    method: "POST",
    id: null,
    data: null,
  },
  uploadFile:{
    url:"/display_ad/admin/upload_file",
    method: "POST",
    id: null,
    data: null,
  },
  removeFile:{
    url:"/display_ad/admin/remove_file",
    method: "POST",
    id: null,
    data: null,
  },
  assignDevice:{
    url:"/display_ad/admin/assign_device/",
    method: "POSTID",
    id: null,
    data: null,

  },
};
