<template>
  <b-modal
    v-model="showModalDevice"
    @hide="handleHide"
    title="Add Device"
    hide-footer
  >
    <b-form @submit.prevent="saveFile">
      <b-form-group label="Select Device">
        <b-form-select
          v-model="selectedItem"
          :options="options"
          required
        ></b-form-select>
      </b-form-group>

      <b-button type="submit" variant="primary" :disabled="isSaving">
        <b-spinner v-if="isSaving" small></b-spinner>
        <span v-if="!isSaving">Save</span>
        <span v-if="isSaving">Saving...</span>
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
import { display_ads } from "@/config/api/display_ads";
import { display_device } from "@/config/api/display_device";

export default {
  props: {
    showModalDevice: {
      type: Boolean,
      default: false,
    },
    displayadDeviceId: {
      type: String,
    },
  },
  data() {
    return {
      selectedItem: null,
      deviceId: null,
      isSaving: false,
      options: [],
    };
  },
  created() {
    this.fetchOptions();
  },
  methods: {
    async fetchOptions() {
      const api = display_device.list;
      this.generateAPI(api)
        .then((res) => {
          this.options = res.data.devices.map((item) => ({
            value: item._id,
            text: item.serial_number,
          }));
          console.log(this.options, res, "this.options");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleHide() {
      this.$emit("close");
    },
    async saveFile() {
      this.isSaving = true;
      console.log(
        this.selectedItem,
        this.displayadDeviceId,
        "this.selectedItem"
      );
      const payload = { deviceId: this.selectedItem };
      const api = display_ads.assignDevice;
      api.id = this.displayadDeviceId;
      api.data = payload;
      this.generateAPI(api)
        .then((res) => {
          this.displayAds = res.data;
          this.isSaving = false;
          this.$emit("refreshAds");
          console.log(res, "error.data");
          this.$bvToast.toast("Devices Assigned!", {
            title: "Ads",
            variant: "success",
            solid: true,
          });
        })
        .catch((error) => {
          console.log(error, "error.data");
          this.$bvToast.toast(`Devices Assign Failed!,${error}`, {
            title: "Ads",
            variant: "danger",
            solid: true,
          });
          this.isSaving = false;
        })
        .finally(() => {
          this.$emit("close");
        });
    },
  },
};
</script>

<style scoped>
.custom-modal-backdrop .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
</style>
